import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';


import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect, FieldLocationAutocompleteInput } from '../../../components';

import css from './SignupForm.module.css';
import { CountrySelector } from '../AuthenticationPage';

const SignupFormComponent = props => {
  const [countryCode, setCountryCode] = useState({ code: 'US' });
  const [country, setCountry] = useState(null);

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
          values,
          signupCountries,
          locationName,
          form: formApi
        } = fieldRenderProps;

        const countryLabel = intl.formatMessage({
          id: 'SignupForm.countryLabel',
        });

        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const addressRequiredMessage = intl.formatMessage({ id: "SignupForm.addressRequired" });
        const addressNotRecognizedMessage = intl.formatMessage({ id: "SignupForm.addressNotRecognized" });

        const formSpyChangeHandler = ({ values, dirty }) => {
          if (values && values.country !== country) {
            setCountryCode({ code: values.country });
            setCountry(values.country);
            formApi.batch(() => {
              formApi.change('phoneNumber', null);
            })
          };
        };

        const validatePhoneNumber = validators.composeValidators(
          validators.required(
            intl.formatMessage({ id: "SignupForm.phoneNumberRequired" })
          ),
          validators.phoneNumberValidator(
            intl.formatMessage({ id: "SignupForm.phoneNumberInvalid" }),
            countryCode.code
          )
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FormSpy onChange={formSpyChangeHandler} subscription={{ values: true, dirty: true }} />
            <div>
              <p className={css.heading}>Almost There!</p>
              <p className={css.subheading}>Create an account or log in,<br /> and we will take you back to the page you came from.</p>
              <div className={css.noticeBox}>
                <p>Welcome...Your details are safe with us! <span className={css.grayText}>Contact information will only be shared once a rental is confirmed.</span></p>
              </div>

              {/* Name Field */}
              <div className={css.name}>
                <div className={css.inputBox}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    autoComplete="given-name"
                    label={intl.formatMessage({
                      id: 'SignupForm.firstNameLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.firstNamePlaceholder',
                    })}
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.firstNameRequired',
                      })
                    )}
                    srOnly={true}
                  />
                </div>
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.lastNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                  srOnly={true}
                />
                <small className={css.note}>Be sure to match name to government ID</small>
              </div>

              {/* Address Field */}
              <FieldLocationAutocompleteInput
                rootClassName={css.locationAddress}
                inputClassName={css.locationAutocompleteInput}
                iconClassName={css.locationAutocompleteInputIcon}
                predictionsClassName={css.predictionsRoot}
                validClassName={css.validLocation}
                name={locationName}
                label={intl.formatMessage({ id: 'SignupForm.locationLabel' })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.locationPlaceholder',
                })}
                useDefaultPredictions={false}
                format={d => d}
                valueFromForm={values.location}
                notShowIcon={true}
                validate={validators.composeValidators(
                  validators.autocompleteSearchRequired(addressRequiredMessage),
                  validators.autocompletePlaceSelected(
                    addressNotRecognizedMessage
                  )
                )}
                srOnly={true}
              />

              {/* Unit Field */}
              <FieldTextInput
                className={css.unitField}
                type="text"
                id={formId ? `${formId}.unit` : 'unit'}
                name="unit"
                autoComplete="unit"
                label={intl.formatMessage({
                  id: 'SignupForm.unitLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.unitPlaceholder',
                })}
                srOnly={true}
              />


              {/* Country Field */}
              <FieldSelect
                id="country"
                name="country"
                className={css.selectCountry}
                autoComplete="country"
                label={countryLabel}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.countryRequired',
                  })
                )}
                srOnly={true}
              >
                <option disabled className={css.countryPlaceholder} value="">
                  {intl.formatMessage({ id: 'SignupForm.countryPlaceholder' })}
                </option>
                {signupCountries.map(c => (
                  <option key={c.code} value={c.code}>
                    {c.country}
                  </option>
                ))}
              </FieldSelect>

              {/* Phone Field */}
              <div className={css.phoneWrapper}>
                <div className={css.phoneInput}>
                  <CountrySelector
                    options={signupCountries}
                    onSelect={(v) => {
                      setCountryCode({ code: v })
                      formApi.change('phoneNumber', null);
                    }}
                    currentUserCountry={countryCode}
                    rootClassName={css.dropdown}
                  />

                  <FieldTextInput
                    className={css.phoneNumber}
                    id={`${formId}.phoneNumber`}
                    name="phoneNumber"
                    label={intl.formatMessage({
                      id: 'SignupForm.phoneLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.phonePlaceholder',
                    })}
                    validate={validatePhoneNumber}
                    srOnly={true}
                  />
                </div>
                <small className={css.note}>Will only contact for support or rental notifications</small>
              </div>

              {/* Email */}
              <div className={css.email}>
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={intl.formatMessage({
                    id: 'SignupForm.emailLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.emailPlaceholder',
                  })}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                  srOnly={true}
                  className={css.email}
                />
                <small className={css.note}>We will email booking information and messages</small>
              </div>

              {/* Password */}
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
                srOnly={true}
              />
            </div>

            <div className={css.bottomWrapper}>
              {termsAndConditions}
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
}


SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;